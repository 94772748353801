<script>
import { SuawParagraph } from "@/components";
export default {
  name: "ChapterEventCardSkeleton",
  components: { SuawParagraph }
};
</script>

<template>
  <div class="suaw-chapter-event-card-skeleton">
    <div class="suaw-chapter-event-card-skeleton__calendar"></div>
    <div class="suaw-chapter-event-card-skeleton__title"></div>
    <div class="suaw-chapter-event-card-skeleton__status"></div>
    <div class="suaw-chapter-event-card-skeleton__chips">
      <div class="suaw-chapter-event-card-skeleton__chip suaw-chapter-event-card-skeleton__chip-time"></div>
      <div class="suaw-chapter-event-card-skeleton__chip"></div>
    </div>
    <div class="suaw-chapter-event-card-skeleton__organizers">
      <SuawParagraph class="suaw-chapter-event-card-skeleton__paragraph" text="Hosted by: " />
      <div class="suaw-chapter-event-card-skeleton__avatar"></div>
      <div class="suaw-chapter-event-card-skeleton__avatar"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chapter-event-card-skeleton {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  padding: 16px;
  width: 100%;
  height: 144px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  column-gap: 16px;
  align-items: center;

  @media (max-width: 650px) {
    box-shadow: var(--date-square-shadow);
    height: 74px;
    padding: 8px;
    grid-template-rows: auto auto;
    column-gap: 12px;
  }

  &__calendar,
  &__title,
  &__status,
  &__chip,
  &__avatar {
    background: linear-gradient(90deg, #f4f4f4 25%, #e0e0e0 50%, #f4f4f4 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
  }

  &__calendar {
    width: 112px;
    height: 112px;
    border-radius: 8px;
    grid-column: 1/2;
    grid-row: 1/4;

    @media (max-width: 650px) {
      width: 58px;
      height: 56px;
      grid-row: 1/3;
    }
  }

  &__title {
    grid-column: 2/3;
    grid-row: 1/2;
    max-width: 400px;
    height: 25px;
    border-radius: 4px;

    @media (max-width: 650px) {
      height: 18px;
      max-width: 200px;
    }
  }

  &__status {
    align-self: flex-start;
    grid-column: 3/4;
    grid-row: 1/2;
    width: 72px;
    height: 28px;
    border-radius: 4px;
  }

  &__chips {
    display: flex;
    gap: 8px;
    grid-column: 2/3;
    grid-row: 2/3;

    @media (max-width: 650px) {
      grid-column: 2/4;
    }
  }

  &__chip,
  &__avatar {
    width: 115px;
    height: 26px;
    border-radius: 16px;

    @media (max-width: 650px) {
      width: 96px;
      height: 19px;
    }
  }

  &__chip-time {
    width: 151px;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &__organizers {
    grid-column: 2/3;
    grid-row: 3/4;
    display: flex;
    gap: 8px;

    @media (max-width: 650px) {
      display: none;
    }
  }

  &__paragraph {
    align-self: center;
    color: var(--sem-color-background-lightest);
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 125%;

    @media (max-width: 429px) {
      display: none;
    }
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }
}
</style>
